import { useEffect, useState } from 'react';
import useMemberSubscription from '@app/web/src/hooks/utils/useMemberSubscription';
import { useAuth } from '@lib/web/apis';

import ClaimAccount from './components/ClaimAccount';
import UpgradePlan from './components/UpgradePlan';
import VerifyEmail from './components/VerifyEmail';

export default function Announcement() {
  const { isPaid, tier } = useMemberSubscription();
  const { member, isGuest } = useAuth();

  const [status, setStatus] = useState({
    claimAccount: false,
    verifyEmail: false,
    upgradePlan: false,
  });

  useEffect(() => {
    setStatus((st) => ({ ...st, claimAccount: isGuest }));
  }, [isGuest]);

  useEffect(() => {
    setStatus((st) => ({
      ...st,
      verifyEmail: member?.emailVerified === false && !isGuest,
    }));
  }, [isGuest, member?.emailVerified]);

  useEffect(() => {
    setStatus((st) => ({
      ...st,
      upgradePlan: tier !== undefined && !isPaid,
    }));
  }, [isPaid, tier]);

  if (!member) return null;
  if (status.claimAccount)
    return (
      <ClaimAccount
        onClose={() => setStatus({ ...status, claimAccount: false })}
      />
    );
  if (status.verifyEmail)
    return (
      <VerifyEmail
        onClose={() => setStatus({ ...status, verifyEmail: false })}
      />
    );
  if (status.upgradePlan)
    return (
      <UpgradePlan
        onClose={() => setStatus({ ...status, upgradePlan: false })}
      />
    );

  return null;
}
