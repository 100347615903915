import { useTranslation } from 'react-i18next';
import { alpha, Skeleton, Theme, Typography } from '@mui/material';
import useChallengeCount from '@app/web/src/hooks/utils/useChallengeCount';
import useMemberClubRole from '@app/web/src/hooks/utils/useMemberClubRole';
import ChallengesExpiredIcon from '@app/web/src/widgets/ChallengesPage/ChallengeIcon/ChallengeExpiredIcon';
import ChallengesInvitedIcon from '@app/web/src/widgets/ChallengesPage/ChallengeIcon/ChallengeInvitedIcon';
import ChallengesOngoingIcon from '@app/web/src/widgets/ChallengesPage/ChallengeIcon/ChallengeOngoingIcon';
import {
  ChatHashtag as ChatHashtagIcon,
  ChatQuestionList as ChatQuestionListIcon,
  NFTFavorite as NFTFavoriteIcon,
  OtherFriendsChallenge as OtherFriendsChallengeIcon,
  OtherFriendsPlaylist as OtherFriendsPlaylistIcon,
  TestBookmarkFilled as TestBookmarkFilledIcon,
  TestHistory as TestHistoryIcon,
  TestWriting as TestWritingIcon,
} from '@front/icon';
import { useIaClubStatus } from '@lib/web/hooks';
import { MenuComps } from '@lib/web/ui';

import useMenuCurrentClubSlug from '../../../../hooks/useMenuCurrentClubSlug';

const getChallengeCountNumber = (number?: number) => {
  if (!number) return '0';
  if (number > 99) return '99+';
  return number;
};

const styles = {
  challengeIcon: {
    svg: {
      width: '12px',
      height: '12px',
    },
  },
  challengeCount: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    px: 1,
    py: '2px',
    fontSize: '12px',
    lineHeight: '16px',
    borderRadius: '16px',
  },
};

export default function PlaylistsMenu() {
  const { t } = useTranslation();
  const clubSlug = useMenuCurrentClubSlug();
  const { club, joined } = useIaClubStatus(clubSlug);
  const { data: challengeCount } = useChallengeCount(clubSlug);
  const { hasEditAuth } = useMemberClubRole();

  if (!club || joined === undefined)
    return <MenuComps title={<Skeleton width={100} />} />;

  return (
    <MenuComps title={t('menu.Playlists')}>
      <MenuComps.Section>
        <MenuComps.SubTitle title="Playlists" />
        <MenuComps.Button
          icon={<TestHistoryIcon />}
          href={`/club/${club.clubSlug}/playlists/history`}
        >
          {t('menu.History')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<TestBookmarkFilledIcon />}
          href={`/club/${club.clubSlug}/playlists/saved`}
        >
          {t('menu.Saved Playlists')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<NFTFavoriteIcon />}
          href={`/club/${club.clubSlug}/playlists/emoji`}
        >
          {t('menu.Reaction')}
        </MenuComps.Button>
      </MenuComps.Section>

      <MenuComps.Section>
        <MenuComps.SubTitle title="Explore" />
        <MenuComps.Button
          icon={<OtherFriendsPlaylistIcon />}
          href={`/club/${club.clubSlug}/playlists/friends-playlists`}
        >
          {t('menu.Followings', "Following's")}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<ChatHashtagIcon />}
          href={`/club/${club.clubSlug}/playlists/hashtags`}
        >
          {t('menu.Hashtags')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<TestWritingIcon />}
          href={`/club/${club.clubSlug}/playlists/creators`}
        >
          {t('menu.Creators')}
        </MenuComps.Button>
      </MenuComps.Section>

      <MenuComps.Section>
        <MenuComps.SubTitle title="Challenges" />
        <MenuComps.Button
          icon={<OtherFriendsChallengeIcon sx={styles.challengeIcon} />}
          href={`/club/${club.clubSlug}/challenges/open-challenges`}
          shallow
          suffix={
            <Typography variant="caption" sx={styles.challengeCount}>
              {getChallengeCountNumber(challengeCount.openChallenges)}
            </Typography>
          }
        >
          {t('menu.Open Challenges')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<ChallengesInvitedIcon sx={styles.challengeIcon} />}
          href={`/club/${club.clubSlug}/challenges/invited`}
          suffix={
            <Typography variant="caption" sx={styles.challengeCount}>
              {getChallengeCountNumber(challengeCount.invited)}
            </Typography>
          }
        >
          {t('menu.Invited')}
        </MenuComps.Button>

        <MenuComps.Button
          icon={<ChallengesOngoingIcon sx={styles.challengeIcon} />}
          href={`/club/${club.clubSlug}/challenges/ongoing`}
          suffix={
            <Typography variant="caption" sx={styles.challengeCount}>
              {getChallengeCountNumber(challengeCount.ongoing)}
            </Typography>
          }
        >
          {t('menu.Ongoing')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<ChallengesExpiredIcon sx={styles.challengeIcon} />}
          href={`/club/${club.clubSlug}/challenges/expired`}
          suffix={
            <Typography variant="caption" sx={styles.challengeCount}>
              {getChallengeCountNumber(challengeCount.expired)}
            </Typography>
          }
        >
          {t('menu.Expired')}
        </MenuComps.Button>
      </MenuComps.Section>

      <MenuComps.Section>
        <MenuComps.SubTitle title="Create" />
        <MenuComps.Button
          icon={<ChatQuestionListIcon />}
          href={`/club/${club.clubSlug}/create-questions`}
        >
          {t('menu.My Questions', 'My Questions')}
        </MenuComps.Button>

        {hasEditAuth && (
          <MenuComps.Button
            icon={<ChatQuestionListIcon />}
            href={`/club/${club.clubSlug}/review-questions`}
          >
            {t('menu.Review Questions')}
          </MenuComps.Button>
        )}
      </MenuComps.Section>
    </MenuComps>
  );
}
