import React from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import useOnboardingData from '@app/web/src/hooks/utils/useOnboardingData';
import useVerifyEmailButton from '@app/web/src/widgets/AccountPage/hooks/useVerifyEmailButton';
import { timeFormat } from '@front/helper';
import { useAuth } from '@lib/web/apis';

import AnnouncementWrap from './AnnouncementWrap';

export default function VerifyEmail({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const { member } = useAuth();

  const { seconds, sent, verifyEmail } = useVerifyEmailButton({
    countdownDelay: 2000,
  });

  const onboardingData = useOnboardingData('playlist');

  const isPlaylistOnboarding =
    !onboardingData?.finished && Router.asPath.includes('/playlist/');
  if (isPlaylistOnboarding) return null;

  return (
    <AnnouncementWrap
      content={t('announcement.verify.content', { email: member?.email })}
      actionComponent={
        <AnnouncementWrap.Button
          onClick={verifyEmail}
          disabled={sent}
          success={sent && !seconds}
        >
          {seconds
            ? timeFormat(seconds)
            : sent
            ? t('announcement.verify.cta.sent')
            : t('announcement.verify.cta.text')}
        </AnnouncementWrap.Button>
      }
      onClose={onClose}
    />
  );
}
