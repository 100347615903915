import { usePathname } from 'next/navigation';
import { includesAny } from '@app/web/src/utils/string';
import { useAuth } from '@lib/web/apis';

import { MenuVariant } from '../context';

const getMenuVariant = (
  pathname: string,
  isLogged?: boolean
): MenuVariant | null => {
  if (pathname === '/extend-trial') return null;
  if (/\/club\/.*\//.test(pathname)) return 'club';
  if (pathname.includes('/profile')) return 'profile';
  if (isLogged && pathname.includes('/marketplace')) return 'profile';
  if (!isLogged && pathname.includes('/marketplace')) return 'marketplace';
  if (includesAny(pathname, '/direct-messages', '/notification'))
    return 'directMessages';
  return 'discover';
};

export default function useMenuVariant() {
  const pathname = usePathname();
  const { isLogged } = useAuth();

  if (!pathname) return null;

  return getMenuVariant(pathname, isLogged);
}
