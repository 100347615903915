import { useMemo } from 'react';
import {
  apis,
  PinChatType,
  PinChatUserStatus,
  PinChatViewSlug,
  useAuth,
  useIaPinChats,
} from '@lib/web/apis';
import { call } from '@lib/web/utils';

export default function useOnboardingData(
  target: 'practice' | 'practice/result' | 'playlist'
) {
  const { data, mutate } = useIaPinChats({
    viewSlug: PinChatViewSlug.PinChatOverlayDefault,
  });

  const { member } = useAuth();
  const userId = member?.userId || '';

  return useMemo(() => {
    if (!data) return undefined;

    const pins = data.data.items.filter(
      (item) =>
        item.referenceId === target && item.type === PinChatType.Onboarding
    );

    const defaultStepNum =
      data.data.items.find((pin) =>
        pin.pinChatUsers.some(
          (user) =>
            user.userId === userId &&
            user.status === PinChatUserStatus.NotChecked
        )
      )?.stepNum ?? 1;

    return {
      finished: pins.every((pin) =>
        pin.pinChatUsers.some(
          (user) =>
            user.userId === userId && user.status === PinChatUserStatus.Checked
        )
      ),
      pins,
      defaultStepNum,
      mutate,
      viewPin: async (pinChatId: string) => {
        await call(
          apis.pinChat.updatePinChatStatus({
            pinChatId,
            status: 1,
          })
        );
      },
    };
  }, [data, target, userId, mutate]);
}
