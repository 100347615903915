import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePathname } from 'next/navigation';
import Router from 'next/router';
import useClaimSignUpUrl from '@app/web/src/hooks/utils/useClaimSignUpUrl';

import AnnouncementWrap from './AnnouncementWrap';

export default function ClaimAccount({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const signUpUrl = useClaimSignUpUrl();
  const pathname = usePathname();

  // https://www.notion.so/rootdomain/Design-7-1-Student-should-see-in-Edit-Profile-page-Will-not-show-unclaimed-account-top-banner-in-56f8b7860b614809b7f62117d5979d4b?pvs=4
  // Student should see, in Edit Profile page: Will not show unclaimed account top banner, instead will have tooltip to remind users
  if (!pathname || pathname === '/profile/account/edit') return null;
  return (
    <AnnouncementWrap
      content={t('announcement.claim.content')}
      actionComponent={
        <AnnouncementWrap.Button
          disabled={!signUpUrl}
          onClick={() => Router.push(signUpUrl)}
        >
          {t('announcement.claim.cta.text')}
        </AnnouncementWrap.Button>
      }
      onClose={onClose}
    />
  );
}
