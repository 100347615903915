import React, {
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { alpha, Box, Theme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { isString } from '@front/helper';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionChevronUp as ActionChevronUpIcon,
} from '@front/icon';
import { IconButton } from '@front/ui';
import RichText from '@lib/ia/src/components/RichText';
import { useAuth } from '@lib/web/apis';
import { useInfiniteScroll } from '@lib/web/hooks';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useChannelsHierarchyDisplay } from '@lib/web/thread/hooks/channels/useChannelsHierarchyDisplay';
import { useFilteredChannels } from '@lib/web/thread/hooks/channels/useFilteredChannels';
import { useReadState } from '@lib/web/thread/hooks/message/useReadState';
import ThreadComposerRenderer from '@lib/web/thread/ThreadTextComposer/ThreadComposerRenderer';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { MenuComps } from '@lib/web/ui';
import { Channel, ChannelFilters, ChannelSort } from 'stream-chat';

import ChannelIcon from './ChannelIcon';

const styles = {
  menu: {
    '& .menu-button-inner': {
      gridTemplateColumns: '1fr',
    },
  },
  titleUnread: {
    fontWeight: 700,
  },
  menuIndent: {
    display: 'flex',
    gap: 1,
    justifyContent: 'space-between',
  },
  menuIndentItem: {
    width: '17px',
    height: '27px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '8px',
      width: '2px',
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  expandPlaceholder: {
    width: '24px',
    height: '24px',
  },
};

/**
 * if child thread channel selected => should open parents by default
 */
function initExpandedMapForCid(
  cid: string,
  allChannels: Channel<StreamChatGenerics>[],
  expandedMap: Record<string, boolean>
) {
  for (const c of allChannels) {
    if (cid === c.cid) {
      const parentId = c.data?.parentChannelCid as string;
      if (parentId) {
        expandedMap[parentId] = true;
        initExpandedMapForCid(parentId, allChannels, expandedMap);
      }
    }
  }
}

function initExpandedMapForUnreadChannels(
  allChannels: Channel<StreamChatGenerics>[],
  expandedMap: Record<string, boolean>,
  isChannelUnread: (channel: Channel<StreamChatGenerics>) => boolean
) {
  for (const c of allChannels) {
    if (isChannelUnread(c)) {
      initExpandedMapForCid(c.cid, allChannels, expandedMap);
    }
  }
}

type MenuThreadChannelRecursiveProps = {
  channels: Channel<StreamChatGenerics>[];
  childChannels: Record<string, Channel<StreamChatGenerics>[]>;
  expandedMap: Record<string, boolean>;
  renderMenuItem: (
    channel: Channel<StreamChatGenerics>,
    nestedLevel: number,
    isDeepest: boolean
  ) => ReactNode;
  level?: number;
};

function MenuThreadChannelRecursive({
  channels,
  childChannels,
  renderMenuItem,
  expandedMap,
  level = 0,
}: MenuThreadChannelRecursiveProps) {
  if (channels.length === 0) return null;
  return (
    <Box>
      {channels.map((channel) => (
        <React.Fragment key={channel.cid}>
          {renderMenuItem(
            channel,
            level,
            (childChannels[channel.cid] || []).length === 0
          )}
          {expandedMap[channel.cid] && (
            <MenuThreadChannelRecursive
              channels={childChannels[channel.cid] || []}
              childChannels={childChannels}
              renderMenuItem={renderMenuItem}
              level={level + 1}
              expandedMap={expandedMap}
            />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default function MenuThreadChannels({
  view,
  channelFilters,
  scrollRef,
  getChannelHref,
  getIsActive,
}: {
  view: GetThreadViewRes;
  channelFilters: ChannelFilters;
  scrollRef: RefObject<HTMLDivElement>;
  getChannelHref: (params: { viewId: string; channelCid: string }) => string;
  getIsActive: (params: { viewId: string; channelCid: string }) => boolean;
}) {
  const { t } = useTranslation('thread');
  const { member } = useAuth();
  const { query } = useRouter();
  const loaded = useRef(false);
  const { getChannelInformation } = useChannelInformation();
  const { getChannelReadState } = useReadState();
  const [expandedMap, setExpandedMap] = useState<Record<string, boolean>>({});
  const myMemberId = member?.memberId;
  const sort: ChannelSort = [{ last_message_at: -1 }];
  const key = JSON.stringify({ key: view.id, filter: channelFilters, sort });

  const channelsData = useFilteredChannels({
    scope: 'menu',
    key,
    filters: channelFilters,
    sort,
  });
  const { dataset: rawChannels, isLoadingInitialData } = channelsData;
  const {
    firstLevelChannels: channels,
    parentCidToChildChannels,
    channelsAndTheirDescendants: allChannels,
  } = useChannelsHierarchyDisplay({ channels: rawChannels });

  const { scrollRef: infiniteScrollRef } = useInfiniteScroll({
    infiniteHookResponse: channelsData,
  });

  useEffect(() => {
    infiniteScrollRef.current = scrollRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loaded.current || !allChannels?.length) return;
    loaded.current = true;
    const cid = (query.cid || '') as string;
    const map: Record<string, boolean> = {};
    initExpandedMapForCid(cid, allChannels, map);
    initExpandedMapForUnreadChannels(
      allChannels,
      map,
      (c) => getChannelReadState(c).isUnread
    );
    setExpandedMap(map);
  }, [allChannels, getChannelReadState, query.cid]);

  if (isLoadingInitialData) {
    return (
      <MenuComps.Button
        icon={<Skeleton variant="rounded" width={16} height={16} />}
      >
        <Skeleton width={140} />
      </MenuComps.Button>
    );
  }

  if (channels.length === 0) {
    return <MenuComps.SubTitle title={t('menu.thread.No threads')} />;
  }

  const handleCollapse = (cid: string) => {
    setExpandedMap({
      ...expandedMap,
      [cid]: !expandedMap[cid],
    });
  };

  return (
    <MenuThreadChannelRecursive
      channels={channels}
      childChannels={parentCidToChildChannels}
      expandedMap={expandedMap}
      renderMenuItem={(channel, nestedLevel, isDeepest) => {
        const {
          lastMessageCreator,
          lastMessageCreatedAt,
          lastMessageText,
          channelEngagedMemberIds,
        } = getChannelInformation(channel);
        const { isUnread } = getChannelReadState(channel);

        if (!lastMessageCreatedAt) return null;

        return (
          <MenuComps.Button
            key={channel.cid}
            icon={
              <ChannelIcon
                memberId={lastMessageCreator}
                avatarBackgroundCount={channelEngagedMemberIds.length - 1}
                avatarBadge={channel.type === 'team' ? 'private' : undefined}
              />
            }
            sx={[isUnread && styles.titleUnread, styles.menu]}
            href={getChannelHref({ viewId: view.id, channelCid: channel.cid })}
            active={getIsActive({ viewId: view.id, channelCid: channel.cid })}
            prefix={
              nestedLevel > 0 && (
                <Box sx={styles.menuIndent}>
                  {[...Array(nestedLevel)].map((_, i) => (
                    <Box key={i} sx={styles.menuIndentItem} />
                  ))}
                </Box>
              )
            }
            extraComponent={
              !isDeepest ? (
                <IconButton
                  customSize={24}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCollapse(channel.cid);
                  }}
                >
                  {!expandedMap[channel.cid] && <ActionChevronUpIcon />}
                  {expandedMap[channel.cid] && <ActionChevronDownIcon />}
                </IconButton>
              ) : (
                <Box sx={styles.expandPlaceholder} />
              )
            }
          >
            {isString(lastMessageText) ? (
              <ThreadComposerRenderer
                html={
                  lastMessageCreator === myMemberId
                    ? `You:&nbsp${lastMessageText}`
                    : lastMessageText
                }
                sx={{ pointerEvents: 'none' }}
                oneLine
              />
            ) : (
              <RichText
                text={
                  lastMessageCreator === myMemberId
                    ? [{ type: 'text', value: 'You:' }, ...lastMessageText]
                    : lastMessageText
                }
                variant="chatBody"
              />
            )}
          </MenuComps.Button>
        );
      }}
    />
  );
}
