import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseLayout, BaseLayoutProps } from '@front/ui';
import { PanelControl } from '@lib/web/ui';

import GlobalRightPanels from '../../components/GlobalRightPanels';

import Announcement from './components/Announcement';
import Header from './components/Header';
import MemberTierWrap from './components/Menu/MemberTierWrap';
import Menu from './components/Menu/Menu';
import Nav from './components/Nav';
import SubNav from './components/SubNav/SubNav';
import useNavMenuVisibility from './hooks/useNavMenuVisibility';
import useReportOnlineMember from './hooks/useReportOnlineMember';
import { MainLayoutProvider } from './context';

type MainLayoutProps = {
  children: BaseLayoutProps['children'];
  pageTitle?: ReactNode;
  pageTitleIcon?: ReactNode;
  pageTitleKey?: string;
  titleSuffix?: ReactNode;
  toolComponent?: ReactNode;
  headerBottomComponent?: ReactNode;
  rightPanelComponent?: BaseLayoutProps['rightPanelComponent'];
  backClickType?: 'back' | 'menu';
};

function MainLayoutChildren({
  children,
  pageTitle,
  pageTitleIcon,
  pageTitleKey,
  titleSuffix,
  toolComponent,
  headerBottomComponent,
  backClickType,
}: MainLayoutProps) {
  useReportOnlineMember();
  const { t } = useTranslation();
  const { menuVisible } = useNavMenuVisibility();

  return (
    <>
      <Header
        title={pageTitleKey ? t(pageTitleKey) : pageTitle}
        icon={pageTitleIcon}
        titleSuffix={titleSuffix}
        toolComponent={toolComponent}
        bottomComponent={headerBottomComponent}
        backClickType={backClickType}
        hideCollapseLeftPanelButton={!menuVisible}
      />
      {children}
      <GlobalRightPanels />
      <PanelControl />
    </>
  );
}

function MainLayout({
  children,
  rightPanelComponent,
  ...rest
}: MainLayoutProps) {
  useReportOnlineMember();
  const { menuVisible, subNavVisible } = useNavMenuVisibility();

  return (
    <BaseLayout
      announcementComponent={<Announcement />}
      navComponent={<Nav />}
      subNavComponent={subNavVisible && <SubNav />}
      leftPanelComponent={
        menuVisible && (
          <MemberTierWrap>
            <Menu />
          </MemberTierWrap>
        )
      }
      rightPanelComponent={rightPanelComponent}
    >
      <MainLayoutChildren {...rest}>{children}</MainLayoutChildren>
    </BaseLayout>
  );
}

export default function MainLayoutRoot({ children, ...rest }: MainLayoutProps) {
  return (
    <MainLayoutProvider>
      <MainLayout {...rest}>{children}</MainLayout>
    </MainLayoutProvider>
  );
}
