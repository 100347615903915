import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import {
  ProfileClubAgent as ProfileClubAgentIcon,
  ProfileMyAgent as ProfileMyAgentIcon,
} from '@front/icon';
import { useIaClubStatus } from '@lib/web/hooks';
import { MenuComps } from '@lib/web/ui';

import useMenuCurrentClubSlug from '../../../../hooks/useMenuCurrentClubSlug';

export default function AgentsMenu() {
  const { t } = useTranslation();
  const clubSlug = useMenuCurrentClubSlug();
  const { club, joined } = useIaClubStatus(clubSlug);
  const scrollRef = useRef<HTMLDivElement>(null);

  if (!club || joined === undefined)
    return <MenuComps title={<Skeleton width={100} />} />;

  return (
    <MenuComps
      title={t('menu.Agents', 'Bots')}
      scrollProps={{
        scrollableNodeProps: {
          ref: scrollRef,
        },
      }}
    >
      <MenuComps.Section>
        <MenuComps.Button
          icon={<ProfileClubAgentIcon />}
          href={`/club/${clubSlug}/agents`}
        >
          {t('menu.Club Agents')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<ProfileMyAgentIcon />}
          href={`/club/${clubSlug}/agents/my-agents`}
        >
          {t('menu.My Agents')}
        </MenuComps.Button>
      </MenuComps.Section>
    </MenuComps>
  );
}
