import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import { useAppSelector } from '@app/web/src/hooks/redux';
import { ThreadPanelKeys, ThreadPanelParams } from '@app/web/src/types/panel';
import {
  MainNotificationOn as MainNotificationOnIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import { useBaseRightPanel } from '@front/ui';
import { apis, ThreadViewType, useAuth } from '@lib/web/apis';
import { useNotifications } from '@lib/web/hooks';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { hashDmViewReferenceId } from '@lib/web/thread/hooks/view/utils';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import { MenuComps } from '@lib/web/ui';
import { call } from '@lib/web/utils';
import { Chat } from 'stream-chat-react';

import DmMenuThreadView from './components/Thread/DmMenuThreadView';

const MISSING_VIEW_CHECK_LIST = [
  ThreadViewType.DmOneOnOne,
  ThreadViewType.DmGroup,
];

const styles = {
  menuWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainMenu: {
    flex: 1,
    '& .menu-content': {
      pt: 1,
    },
  },
};

function DirectMessagesMenuContent() {
  const { t } = useTranslation('thread');

  const scrollRef = useRef<HTMLDivElement>(null);
  const { views, suggestionViews, reloadViews } = useDirectMessageViews();
  const { globalNotificationCount } = useNotifications();
  const { isBlocking, blockerTip } = useAuth();
  const { openRightPanel } = useBaseRightPanel<ThreadPanelParams>();
  const layoutType = useAppSelector((st) => st.iaLayout.type) || 'table';

  const { unreadChannels } = useThread();
  const { getChannelInformation } = useChannelInformation();

  const newThreadButtonProps =
    {
      channel: { href: '/direct-messages/new-thread' },
      table: {
        onClick: () => openRightPanel(ThreadPanelKeys.DmThreadNewThread),
      },
    }[layoutType] || {};

  useEffect(() => {
    const maybeUpdateMissingDmViews = async () => {
      // public channel doesn't have dm views, so we can ignore them
      const filteredUnreadChannels = unreadChannels.filter(
        (channel) => channel.type !== 'public'
      );

      // Check if there are any missing views for this unread channel
      const updateDmPromises = filteredUnreadChannels.map((unreadChannel) => {
        const { channelMemberIds } = getChannelInformation(unreadChannel);
        const unreadChannelReferenceId =
          hashDmViewReferenceId(channelMemberIds);
        const haveMissingViews = !views
          .filter(
            (view) =>
              MISSING_VIEW_CHECK_LIST.includes(view.type) && view.referenceId
          )
          .some((view) => view.referenceId === unreadChannelReferenceId);
        // If there are missing views, create new view with the channel member IDs
        if (haveMissingViews) {
          return call(
            apis.thread.maybeUpdateDmThreadView({
              memberIdsBefore: [],
              memberIdsAfter: channelMemberIds,
            })
          );
        }
      });

      await Promise.all(updateDmPromises);
      await reloadViews();
    };

    void maybeUpdateMissingDmViews();
  }, [getChannelInformation, reloadViews, unreadChannels, views]);

  return (
    <Box sx={styles.menuWrapper}>
      <MenuComps
        title={t('menu.DirectMessages')}
        scrollProps={{
          showShadow: true,
          shadowPosition: 'top',
          scrollableNodeProps: {
            ref: scrollRef,
          },
        }}
      >
        <MenuComps.Section>
          <MenuComps.Button
            icon={<TestAddIcon />}
            disabled={isBlocking}
            tooltip={blockerTip}
            {...newThreadButtonProps}
          >
            New Thread
          </MenuComps.Button>
          <MenuComps.Button
            icon={<MainNotificationOnIcon />}
            href={`/notifications`}
            badgeValue={globalNotificationCount}
          >
            {t('menu.Notifications')}
          </MenuComps.Button>
          {views.map((view) => (
            <DmMenuThreadView key={view.id} view={view} scrollRef={scrollRef} />
          ))}
        </MenuComps.Section>
        {suggestionViews.length > 0 && (
          <MenuComps.Section>
            <MenuComps.SubTitle title={t('menu.Suggestions')} />
            {suggestionViews.map((view) => (
              <DmMenuThreadView
                key={view.id}
                view={view}
                scrollRef={scrollRef}
              />
            ))}
          </MenuComps.Section>
        )}
      </MenuComps>
    </Box>
  );
}

export default function DirectMessagesMenu() {
  const { chatClient } = useThread();
  const { query } = useRouter();
  const viewId = query.viewId as string;

  if (!chatClient) return null;

  return (
    <Chat client={chatClient}>
      <DirectMessagesMenuContent key={viewId} />
    </Chat>
  );
}
