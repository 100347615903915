import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getClubIconPath } from '@app/web/src/utils/clubs';
import {
  MainBrowse as MainBrowseIcon,
  OtherShoppingCart as OtherShoppingCartIcon,
  ThreadsDirectMessages as ThreadsDirectMessagesIcon,
} from '@front/icon';
import {
  Logo,
  Scrollbar,
  SquareAvatar,
  useBaseLayout,
  useBaseLeftPanel,
  useBaseRightPanel,
} from '@front/ui';
import AvatarGridFolder from '@lib/ia/src/components/AvatarGridFolder';
import { useAuth } from '@lib/web/apis';
import { useCurrentIaClub, useNotifications } from '@lib/web/hooks';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { getClubGroupIconPath } from '@lib/web/utils';

import { MenuVariant } from '../context';
import useCurrentMenu from '../hooks/useCurrentMenu';
import useMainLayout from '../hooks/useMainLayout';
import useMenuCurrentClub from '../hooks/useMenuCurrentClub';
import useMenuVariant from '../hooks/useMenuVariant';

import NavButton from './NavButtons/NavButton';
import NavActions from './NavActions';

const styles = {
  nav: {
    height: '100%',
    position: 'relative',
    bgcolor: '#151515',
    borderRight: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.05)}`,
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  },
  scrollWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  contentWrapper: {
    width: '100%',
    position: 'relative',
  },
  content: {
    pt: '12px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },
  notificationDot: {
    position: 'absolute',
    top: -5,
    right: 5,
    color: 'error.dark',
    pointerEvents: 'none',
  },
  logo: {
    cursor: 'pointer',
    width: 32,
    height: 32,
  },
  discoverButton: {
    '& .menu-icon:not(.active)': {
      background: 'transparent',
    },
  },
  clubGroupNavButton: {
    '& .menu-button-inner': {
      width: 32,
      height: 36, // club group icon has 4px stack on the bottom, which make button exceed the container, cause mouse hover on it will blink, so we add 4px in height, use mb: -4
      mb: '-4px',
    },
  },
  clubGroupFolderWrapper: {
    '& .menu-button-inner': {
      width: 32,
      height: 32,
    },
  },
  clubGroupFolder: {
    display: 'grid',
    gridTemplate: '1fr 1fr / 1fr 1fr',
    gap: '2px',
    width: '22px',
    height: '22px',
    justifyItems: 'center',
    alignItems: 'center',
  },
};

interface ClubGridFolderIconProps {
  clubs: ClubUnreadNotification[];
}

function ClubGridFolderIcon({ clubs = [] }: ClubGridFolderIconProps) {
  const clubAvatars = clubs.map((club) => {
    return {
      name: club.name,
      avatarUrl: club.iconPath || getClubIconPath(club.name),
    };
  });

  return <AvatarGridFolder avatars={clubAvatars} />;
}

interface ClubGroupButtonProps {
  clubGroupNotification: ClubGroupUnreadNotification;
}

function ClubGroupButton({ clubGroupNotification }: ClubGroupButtonProps) {
  const { rightPanelOpened, closeRightPanel } = useBaseRightPanel();
  const { enableLeftPanel, openLeftPanel } = useBaseLeftPanel();
  const { seeClubGroup } = useMainLayout();
  const { club: currentClub } = useCurrentIaClub();

  const clubInsideGroup = clubGroupNotification.clubs.some(
    (c) => c.slug === currentClub?.clubSlug
  );

  const clubGroupIconPath =
    clubGroupNotification.iconPath ||
    getClubGroupIconPath(clubGroupNotification.name);

  const currentClubIconPath =
    currentClub?.clubLogo || getClubIconPath(currentClub?.clubName || '');

  const handleSeeClubGroup = () => {
    if (rightPanelOpened) closeRightPanel();
    seeClubGroup(clubGroupNotification.id);
    enableLeftPanel();
    openLeftPanel();
  };

  const showAsFolder = !clubInsideGroup && !clubGroupNotification.iconPath;

  return (
    <NavButton
      hasNotification={clubGroupNotification.count > 0}
      onClick={handleSeeClubGroup}
      active={clubInsideGroup}
      tooltip={clubGroupNotification.name}
      mask={false} // club group icon is always masked, and if we use NavButton mask, it will hide the club group's stacked
      sx={[
        styles.clubGroupNavButton,
        showAsFolder && styles.clubGroupFolderWrapper,
      ]}
    >
      {showAsFolder ? (
        <ClubGridFolderIcon clubs={clubGroupNotification.clubs} />
      ) : (
        <SquareAvatar
          src={clubInsideGroup ? currentClubIconPath : clubGroupIconPath}
          size={32}
          blackAndWhite
          showStacked
        >
          {clubGroupNotification.name}
        </SquareAvatar>
      )}
    </NavButton>
  );
}

interface ClubButtonProps {
  clubNotification: ClubUnreadNotification;
}

function ClubButton({ clubNotification }: ClubButtonProps) {
  const validIconPath =
    clubNotification.iconPath || getClubIconPath(clubNotification.name || '');
  const { seeClub, clearMenu } = useMainLayout();
  const { club: currentClub } = useMenuCurrentClub();
  const { rightPanelOpened, closeRightPanel } = useBaseRightPanel();
  const { currentMenu } = useCurrentMenu();
  const { unreadChannels } = useThread();

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const active =
    currentMenu === 'club' && currentClub?.clubSlug === clubNotification.slug;

  const hasUnreadChannelsForThisClub = useMemo(
    () =>
      unreadChannels.some(
        (channel) => channel.data?.clubId === clubNotification.id
      ),
    [unreadChannels, clubNotification.id]
  );

  const handleSeeClub = () => {
    if (mdUp && !active) {
      if (rightPanelOpened) closeRightPanel();
      void Router.push(
        clubNotification.isSubscribed
          ? `/club/${clubNotification.slug}/start`
          : `/club/${clubNotification.slug}/notifications`
      );
    }
    clearMenu();
    seeClub(clubNotification.slug);
  };

  return (
    <NavButton
      hasNotification={
        clubNotification.count > 0 || hasUnreadChannelsForThisClub
      }
      onClick={handleSeeClub}
      active={active}
      tooltip={clubNotification.name}
    >
      <SquareAvatar src={validIconPath} size={32}>
        {clubNotification.name}
      </SquareAvatar>
    </NavButton>
  );
}

function NotLoggedNav() {
  const { t } = useTranslation();

  const { menuTarget, seeMenu } = useMainLayout();
  const menuVariant = useMenuVariant();
  const currentMenu = menuTarget || menuVariant;
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleSeeMenu = (target: MenuVariant, href: string) => {
    if (mdUp && href && menuVariant !== target) Router.push(href);
    seeMenu(target);
  };

  return (
    <Box sx={styles.nav}>
      <Scrollbar sx={styles.scroll}>
        <Box sx={styles.content}>
          <NavButton
            onClick={() => Router.push('https://www.earnaha.com/')}
            tooltip="Aha"
          >
            <Logo />
          </NavButton>
          <NavButton
            onClick={() =>
              handleSeeMenu('marketplace', '/marketplace/available')
            }
            active={currentMenu === 'marketplace'}
            tooltip={t('menu.Avatar Marketplace')}
          >
            <OtherShoppingCartIcon />
          </NavButton>
        </Box>
      </Scrollbar>
    </Box>
  );
}

function ClubsNav() {
  const { clubGroupNotifications, clubNotifications } = useNotifications();

  return (
    <>
      {clubGroupNotifications.map((clubGroupNotification) => (
        <ClubGroupButton
          key={clubGroupNotification.id}
          clubGroupNotification={clubGroupNotification}
        />
      ))}
      {clubNotifications.map((clubNotification) => (
        <ClubButton
          key={clubNotification.slug}
          clubNotification={clubNotification}
        />
      ))}
    </>
  );
}
export default function Nav() {
  const { t } = useTranslation();
  const { member } = useAuth();
  const { seeMenu, clearMenu } = useMainLayout();
  const { mainNavOpened } = useBaseLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { currentMenu } = useCurrentMenu();
  const { unreadChannels } = useThread();
  const { globalNotificationCount } = useNotifications();

  const handleSeeMenu = (target: MenuVariant, href: string) => {
    if (mdUp) {
      void Router.push(href);
    }
    clearMenu();
    seeMenu(target);
  };

  // XXX: it seems redundant, but without this,
  // SimpleTooltip inside NavButton will raise error: "MUI: The `anchorEl` prop provided to the component is invalid."
  // cause tooltip flash
  if (!mainNavOpened) return null;

  if (!member) return <NotLoggedNav />;

  return (
    <Box sx={styles.nav}>
      <Box sx={styles.scrollWrapper}>
        <Scrollbar sx={styles.scroll}>
          <Box sx={styles.content}>
            <NavButton
              onClick={() => handleSeeMenu('directMessages', '/notifications')}
              active={currentMenu === 'directMessages'}
              tooltip={t('menu.DirectMessages')}
              hasNotification={
                unreadChannels.length > 0 || globalNotificationCount > 0
              }
            >
              <ThreadsDirectMessagesIcon />
            </NavButton>

            <ClubsNav />

            <NavButton
              onClick={() => handleSeeMenu('discover', '/')}
              active={currentMenu === 'discover'}
              tooltip={t('menu.Discover')}
              sx={styles.discoverButton}
            >
              <MainBrowseIcon />
            </NavButton>
          </Box>
        </Scrollbar>
      </Box>
      <NavActions />
    </Box>
  );
}
