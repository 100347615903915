import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Box, Skeleton, Theme, useMediaQuery } from '@mui/material';
import { ActionDoubleRightChevron as ActionDoubleRightChevronIcon } from '@front/icon';
import { SquareAvatar, useBaseLayout } from '@front/ui';
import { useAuth } from '@lib/web/apis';

import useCurrentMenu from '../hooks/useCurrentMenu';
import useMainLayout from '../hooks/useMainLayout';

import NavButton from './NavButtons/NavButton';
import SubNavButton from './NavButtons/SubNavButton';

const styles = {
  root: {
    height: 'auto',
    py: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
  },
  navSwitcher: {
    '& .menu-icon': {
      background: 'transparent',
    },
  },
  profileDisabled: {
    cursor: 'default',
    opacity: 1,
  },
};

type NavActionsProps = {
  profileDisabled?: boolean;
  showCloseNavButton?: boolean;
};

export default function NavActions({
  profileDisabled,
  showCloseNavButton = false,
}: NavActionsProps) {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { member } = useAuth();
  const { mainNavOpened, openMainNav } = useBaseLayout();
  const { seeMenu, seeProfileMenu, clearMenu } = useMainLayout();
  const { currentMenu } = useCurrentMenu();

  const handleProfileClick = () => {
    if (mdUp) {
      void Router.push('/profile/account');
    }
    clearMenu();
    seeMenu('profile');
    // reset the profile menu to default
    seeProfileMenu(null);
  };
  return (
    <Box sx={styles.root}>
      {showCloseNavButton && (
        <SubNavButton
          onClick={openMainNav}
          tooltip={t('menu.Show outer navigation')}
        >
          <ActionDoubleRightChevronIcon />
        </SubNavButton>
      )}
      {member ? (
        <NavButton
          onClick={handleProfileClick}
          tooltip={!profileDisabled ? t('menu.Profile') : undefined}
          disabled={profileDisabled}
          sx={[!!profileDisabled && styles.profileDisabled]}
          active={mainNavOpened && currentMenu === 'profile'}
          hasNotification={member.emailVerified === false && !profileDisabled}
        >
          <SquareAvatar size={40} src={member.avatarUrl || member.photoUrl}>
            {member.displayName || member.distinctName}
          </SquareAvatar>
        </NavButton>
      ) : (
        <SquareAvatar size={32}>
          <Skeleton width={32} height={32} variant="rectangular" />
        </SquareAvatar>
      )}
    </Box>
  );
}
