import { ChallengeCategoryStatus, useChallengeAll } from '@lib/web/apis';
import { useIaClub } from '@lib/web/hooks';

export default function useChallengeCount(clubSlug: string) {
  const { sectionId } = useIaClub(clubSlug);

  const { data: invitedCount, mutate: invitedMutate } = useChallengeAll(
    sectionId,
    ChallengeCategoryStatus.Invited
  );
  const { data: ongoingCount, mutate: ongoingMutate } = useChallengeAll(
    sectionId,
    ChallengeCategoryStatus.Ongoing
  );
  const { data: expiredCount, mutate: expiredMutate } = useChallengeAll(
    sectionId,
    ChallengeCategoryStatus.Expired
  );
  const { data: openChallengeCount, mutate: openChallengeMutate } =
    useChallengeAll(sectionId, ChallengeCategoryStatus.OpenChallenge);

  const mutate = () => {
    invitedMutate();
    ongoingMutate();
    expiredMutate();
    openChallengeMutate();
  };

  return {
    mutate,
    data: {
      invited: invitedCount?.data.totalCount,
      ongoing: ongoingCount?.data.totalCount,
      expired: expiredCount?.data.totalCount,
      openChallenges: openChallengeCount?.data.totalCount,
    },
  };
}
