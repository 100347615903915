import { useMemo } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import useClubMenuVariant from './useClubMenuVariant';
import useMainLayout from './useMainLayout';
import useMenuVariant from './useMenuVariant';
import useProfileMenuVariant from './useProfileMenuVariant';

export default function useNavMenuVisibility() {
  const menuVariant = useMenuVariant();
  const clubMenuVariant = useClubMenuVariant();
  const profileMenuVariant = useProfileMenuVariant();
  const { menuTarget, temporaryMenuTarget, clubMenuTarget, profileMenuTarget } =
    useMainLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const menuVisible = useMemo(() => {
    const currentMenu = mdUp ? menuVariant : menuTarget || menuVariant;
    const currentClubMenu = mdUp
      ? clubMenuVariant
      : clubMenuTarget || clubMenuVariant;
    const currentProfileMenu = mdUp
      ? profileMenuVariant
      : profileMenuTarget || profileMenuVariant;

    if (temporaryMenuTarget === 'clubGroup') {
      return true;
    }

    if (currentMenu === 'profile') {
      // profile nav with only avatars has LHS menu
      return (
        currentProfileMenu === 'avatars' || currentProfileMenu === 'discount'
      );
    }
    if (currentMenu === 'club') {
      // practice and rankings nav there is no LHS menu
      return (
        currentClubMenu &&
        currentClubMenu !== 'practice' &&
        currentClubMenu !== 'rankings'
      );
    }
    if (currentMenu === 'directMessages' || currentMenu === 'discover') {
      return true;
    }
    return false;
  }, [
    mdUp,
    menuVariant,
    menuTarget,
    clubMenuVariant,
    clubMenuTarget,
    profileMenuVariant,
    profileMenuTarget,
    temporaryMenuTarget,
  ]);

  const subNavVisible = useMemo(() => {
    const currentMenu = mdUp ? menuVariant : menuTarget || menuVariant;

    if (temporaryMenuTarget === 'clubGroup') {
      return false;
    }

    return currentMenu === 'club' || currentMenu === 'profile';
  }, [mdUp, menuVariant, menuTarget, temporaryMenuTarget]);

  return {
    menuVisible,
    subNavVisible,
  };
}
