import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import useMemberSubscription from '@app/web/src/hooks/utils/useMemberSubscription';
import { trackEvent } from '@lib/web/utils';

import AnnouncementWrap from './AnnouncementWrap';

const HIDE_PATHNAMES_REGEX =
  /\/checkout|\/extend-trial|\/quiz\/practice|\/quiz\/exam/i;

export default function UpgradePlan({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const { isExpired } = useMemberSubscription();
  const router = useRouter();

  const goExtendTrialPage = () => {
    trackEvent('click', {
      elementName: 'upgrade-button',
      fromLocation: 'top-bar-banner',
    });
    void router.push('/extend-trial');
  };

  if (HIDE_PATHNAMES_REGEX.test(router.pathname || '')) return null;

  return (
    <AnnouncementWrap
      content={
        isExpired
          ? t('announcement.upgrade.content.expired')
          : t('announcement.upgrade.content.ongoing')
      }
      actionComponent={
        <AnnouncementWrap.Button onClick={goExtendTrialPage}>
          {t('announcement.upgrade.cta.text')}
        </AnnouncementWrap.Button>
      }
      onClick={goExtendTrialPage}
      onClose={onClose}
    />
  );
}
