import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import {
  AnalyticsMyPath as AnalyticsMyPathIcon,
  AnalyticsProgress as AnalyticsProgressIcon,
  MainSetGoal as MainSetGoalIcon,
  MenuThumbsDownOutline as MenuThumbsDownOutlineIcon,
  OtherWarning as OtherWarningIcon,
  TestClock as TestClockIcon,
} from '@front/icon';
import { useIaClubStatus } from '@lib/web/hooks';
import { MenuComps } from '@lib/web/ui';

import useMenuCurrentClubSlug from '../../../../hooks/useMenuCurrentClubSlug';

export default function AnalyticsMenu() {
  const { t } = useTranslation();
  const clubSlug = useMenuCurrentClubSlug();
  const { club, joined } = useIaClubStatus(clubSlug);

  if (!club || joined === undefined)
    return <MenuComps title={<Skeleton width={100} />} />;

  return (
    <MenuComps title={t('menu.Analytics')}>
      <MenuComps.Section>
        <MenuComps.Button
          icon={<AnalyticsProgressIcon />}
          href={`/club/${club.clubSlug}/summary`}
        >
          {t('menu.Summary')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<MainSetGoalIcon />}
          href={`/club/${club.clubSlug}/analytics/goals`}
        >
          {t('menu.Goal Rings')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<AnalyticsMyPathIcon />}
          href={`/club/${club.clubSlug}/analytics/my-path`}
        >
          {t('menu.Compare Paths')}
        </MenuComps.Button>
      </MenuComps.Section>
      <MenuComps.Section>
        <MenuComps.SubTitle title="Weakness" />
        <MenuComps.Button
          icon={<OtherWarningIcon />}
          href={`/club/${club.clubSlug}/analytics/weakness/total-wrong`}
        >
          {t('menu.Mistakes')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<TestClockIcon />}
          href={`/club/${club.clubSlug}/analytics/weakness/overtime`}
        >
          {t('menu.Overtime')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<MenuThumbsDownOutlineIcon />}
          href={`/club/${club.clubSlug}/analytics/weakness/out-of-practice`}
        >
          {t('menu.Have Not Practiced Yet', 'Haven’t Practiced Yet')}
        </MenuComps.Button>
      </MenuComps.Section>
      <MenuComps.Section>
        <MenuComps.SubTitle title="Overall" />
        <MenuComps.Button
          icon={<OtherWarningIcon />}
          href={`/club/${club.clubSlug}/analytics/mistakes/total-wrong`}
        >
          {t('menu.Mistakes')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<TestClockIcon />}
          href={`/club/${club.clubSlug}/analytics/mistakes/overtime`}
        >
          {t('menu.Overtime')}
        </MenuComps.Button>
      </MenuComps.Section>
    </MenuComps>
  );
}
