import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Router from 'next/router';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useClubGroupClubs } from '@app/web/src/ia/clubGroup/useClubGroupClubs';
import { useDiscoverClubGroups } from '@app/web/src/ia/clubGroup/useDiscoverClubGroups';
import useMainLayout from '@app/web/src/layouts/MainLayout/hooks/useMainLayout';
import {
  ActionMore as ActionMoreIcon,
  OtherDelete as OtherDeleteIcon,
  ProfileLogout as ProfileLogoutIcon,
  ProfileSetting as ProfileSettingIcon,
} from '@front/icon';
import { IconButton, popup, ResponsiveMenu } from '@front/ui';
import { apis, useAuth } from '@lib/web/apis';
import { useNotifications } from '@lib/web/hooks';
import { callWithToast } from '@lib/web/utils';

const styles = {
  menu: {
    mx: 0,
  },
  deleteGroupItem: {
    color: 'error.dark',
  },
};

export type ClubGroupMenuToolComponentProps = {
  clubGroupNotification: ClubGroupUnreadNotification;
};

export default function ClubGroupMenuToolComponent({
  clubGroupNotification,
}: ClubGroupMenuToolComponentProps) {
  const { t } = useTranslation('club');
  const pathname = usePathname();
  const { clearMenu } = useMainLayout();
  const { mutate: clubNotificationsMutate } = useNotifications();
  const { mutate: discoverClubGroupsMutate } = useDiscoverClubGroups();
  const { mutate: clubGroupClubsMutate } = useClubGroupClubs(
    clubGroupNotification.id
  );
  const { userId } = useAuth();

  const menuAnchorEl = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const isCreator = userId
    ? userId === clubGroupNotification.creator.sourceId
    : false;

  if (!clubGroupNotification) return null;

  const showDeleteGroupItem = isCreator;
  const showLeaveGroupItem = !isCreator;
  const showLeaveGroupAndClubsItem = !isCreator;

  const handleDeleteClubGroupClick = async (ev: React.MouseEvent) => {
    setMenuOpen(false);
    const confirmed = await popup.promise({
      content: t(
        'clubGroup.toolbar.confirm.deleteGroup',
        'Deleting this club group will move all clubs out of the club group. Are you sure you want to delete your club group?'
      ),
      anchorEl: ev.target as HTMLButtonElement,
      confirmText: 'Delete',
      popupType: 'warning',
    });
    if (!confirmed) return;
    await callWithToast(
      apis.clubGroup.deleteClubGroup(clubGroupNotification.id)
    );
    void clubNotificationsMutate();
    void discoverClubGroupsMutate();
    clearMenu();

    if (pathname === `/club-group/${clubGroupNotification.id}`) {
      void Router.push('/club-group');
    }
  };
  const handleLeaveClubGroupClick = async (ev: React.MouseEvent) => {
    setMenuOpen(false);
    const confirmed = await popup.promise({
      content: t(
        'clubGroup.toolbar.confirm.leaveGroup',
        'You will still be a member of the clubs inside this club group. Are you sure you want to leave the club group?'
      ),
      anchorEl: ev.target as HTMLButtonElement,
      confirmText: 'Leave',
      popupType: 'warning',
    });
    if (!confirmed) return;

    await callWithToast(
      apis.clubGroup.leaveClubGroup({
        id: clubGroupNotification.id,
        leaveAllClubs: false,
      })
    );
    void clubNotificationsMutate();
    void discoverClubGroupsMutate();
    void clubGroupClubsMutate();
    clearMenu();
  };

  const handleLeaveClubGroupAndClubsClick = async (ev: React.MouseEvent) => {
    setMenuOpen(false);
    const confirmed = await popup.promise({
      content: t(
        'clubGroup.toolbar.confirm.leaveGroupAndClubs',
        'You will leave the club group and all clubs inside of this group. Are you sure you want to leave?'
      ),
      anchorEl: ev.target as HTMLButtonElement,
      confirmText: 'Leave',
      popupType: 'warning',
    });
    if (!confirmed) return;

    await callWithToast(
      apis.clubGroup.leaveClubGroup({
        id: clubGroupNotification.id,
        leaveAllClubs: true,
      })
    );
    void clubNotificationsMutate();
    void discoverClubGroupsMutate();
    void clubGroupClubsMutate();
    clearMenu();
  };

  return (
    <>
      <IconButton
        ref={menuAnchorEl}
        customSize={24}
        onClick={() => setMenuOpen(true)}
      >
        <ActionMoreIcon />
      </IconButton>

      <ResponsiveMenu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        menuProps={{
          sx: styles.menu,
          anchorEl: menuAnchorEl.current,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        sheetProps={{
          fixedHeight: true,
        }}
      >
        <MenuItem
          component={Link}
          href={`/club-group/form/${clubGroupNotification.id}/settings`}
        >
          <ListItemIcon>
            <ProfileSettingIcon width={16} height={16} />
          </ListItemIcon>

          <ListItemText>
            {t('clubGroup.toolbar.clubGroupSettings')}
          </ListItemText>
        </MenuItem>
        {showDeleteGroupItem && (
          <MenuItem
            onClick={handleDeleteClubGroupClick}
            sx={styles.deleteGroupItem}
          >
            <ListItemIcon>
              <OtherDeleteIcon width={16} height={16} />
            </ListItemIcon>
            <ListItemText>{t('clubGroup.toolbar.deleteGroup')}</ListItemText>
          </MenuItem>
        )}
        {showLeaveGroupItem && (
          <MenuItem onClick={handleLeaveClubGroupClick}>
            <ListItemIcon>
              <ProfileLogoutIcon width={16} height={16} />
            </ListItemIcon>

            <ListItemText>{t('clubGroup.toolbar.leaveClubGroup')}</ListItemText>
          </MenuItem>
        )}
        {showLeaveGroupAndClubsItem && (
          <MenuItem onClick={handleLeaveClubGroupAndClubsClick}>
            <ListItemIcon>
              <ProfileLogoutIcon width={16} height={16} />
            </ListItemIcon>
            <ListItemText>
              {t('clubGroup.toolbar.leaveClubGroupAndClubs')}
            </ListItemText>
          </MenuItem>
        )}
      </ResponsiveMenu>
    </>
  );
}
