import { MouseEvent, ReactNode, useEffect, useRef } from 'react';
import { alpha, Fade, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDimension } from '@front/helper';
import { ActionClose as ActionCloseIcon } from '@front/icon';
import { Button, IconButton } from '@front/ui';

const styles = {
  root: (theme: Theme) => ({
    zIndex: 'modal',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed' as const,
      bottom: 20,
      left: 20,
      right: 20,
    },
  }),
  inner: (theme: Theme) => ({
    background: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      p: 2,
      pr: '52px',
      display: 'grid',
      gap: 1,
      borderRadius: 1,
    },
    [theme.breakpoints.up('sm')]: {
      pl: 2.5,
      pr: '75px',
      py: 1.25,
      display: 'flex',
      justifyContent: 'space-between' as const,
      alignItems: 'center',
    },
  }),
  title: {
    fontWeight: 400,
    fontSize: { xs: 14, md: 16 },
    color: (theme: Theme) => theme.palette.background.darker,
    flex: 1,
  },
  button: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    borderColor: (theme: Theme) => theme.palette.background.darker,
    color: (theme: Theme) => theme.palette.background.darker,
    '&:hover': {
      bgcolor: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? 'none'
          : theme.palette.background.darker,
      borderColor: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? 'none'
          : alpha(theme.palette.text.primary, 0.3),
      color: (theme: Theme) =>
        theme.palette.mode === 'dark' ? 'none' : theme.palette.text.primary,
    },
    '&:disabled': {
      color: (theme: Theme) => theme.palette.background.darker,
      opacity: 0.3,
    },
  },
  buttonSuccess: {
    borderColor: (theme: Theme) => theme.palette.success.dark,
  },
  actionIcon: {
    position: 'absolute',
    right: 16,
    top: { xs: 16, sm: '50%' },
    transform: { sm: 'translateY(-50%)' },
    '& svg': {
      color: (theme: Theme) => theme.palette.background.darker,
    },
  },
};

function AnnouncementWrapButton({
  children,
  disabled,
  success,
  onClick,
}: {
  children: ReactNode;
  disabled?: boolean;
  success?: boolean;
  onClick: () => void;
}) {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <Button
      sx={[styles.button, !!success && styles.buttonSuccess]}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

type AnnouncementWrapContentProps = {
  content: string;
  actionComponent: ReactNode;
  onClick?: () => void;
  onClose?: () => void;
};
function AnnouncementWrapContent({
  content,
  actionComponent,
  onClick,
  onClose,
}: AnnouncementWrapContentProps) {
  const boxRef = useRef();
  const { height: innerHeight } = useDimension(boxRef);

  const handleClickClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClose?.();
  };

  useEffect(() => {
    const root = document.body;
    root.style.setProperty('--announcement-bar-height', innerHeight + 'px');
  }, [innerHeight]);

  useEffect(() => {
    return () => {
      const root = document.body;
      root.style.setProperty('--announcement-bar-height', '0px');
    };
  }, []);

  return (
    <Box ref={boxRef} sx={styles.root} onClick={onClick}>
      <Box sx={styles.inner}>
        <Typography sx={styles.title}>{content}</Typography>

        {actionComponent}
        <IconButton
          customSize={24}
          onClick={handleClickClose}
          sx={styles.actionIcon}
        >
          <ActionCloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
export default function AnnouncementWrap({
  ...rest
}: AnnouncementWrapContentProps) {
  return (
    <Fade in unmountOnExit>
      <div>
        <AnnouncementWrapContent {...rest} />
      </div>
    </Fade>
  );
}

AnnouncementWrap.Button = AnnouncementWrapButton;
